/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { loadReCaptcha } from 'react-recaptcha-v3';

// Replace 'your-recaptcha-site-key' with your actual reCAPTCHA v3 site key
const RECAPTCHA_SITE_KEY = '6LdxumApAAAAABSV5y58Yoq9wzL6o9VLr1QT4iyq';

// export const wrapRootElement = ({ element }) => (
//   <GoogleReCaptchaProvider siteKey={RECAPTCHA_SITE_KEY} >
//     {element}
//   </GoogleReCaptchaProvider>
// );


export const onClientEntry = async () => {
  await loadReCaptcha(RECAPTCHA_SITE_KEY);
};
