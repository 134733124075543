exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-milpark-mews-js": () => import("./../../../src/pages/location/milpark-mews.js" /* webpackChunkName: "component---src-pages-location-milpark-mews-js" */),
  "component---src-pages-location-roshnee-js": () => import("./../../../src/pages/location/roshnee.js" /* webpackChunkName: "component---src-pages-location-roshnee-js" */),
  "component---src-pages-location-rustervaal-js": () => import("./../../../src/pages/location/rustervaal.js" /* webpackChunkName: "component---src-pages-location-rustervaal-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-uisp-js": () => import("./../../../src/pages/uisp.js" /* webpackChunkName: "component---src-pages-uisp-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-application-js": () => import("./../../../src/templates/application.js" /* webpackChunkName: "component---src-templates-application-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */)
}

